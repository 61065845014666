import React from 'react'

// Components
import Layout from '../components/Layout'
import {GallerySolarSystem}  from '../components/Gallery'

const SolarSystemPage = ({}) => (
  <>
    <Layout />
    <GallerySolarSystem />
  </>
)

export default SolarSystemPage
